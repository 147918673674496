import request from '@/utils/request'

// 首页厅房列表
export function findHalls(params) {
  return request({
    url: `/halls`,
    method: 'get',
    params: params
  })
}

export function validUser(id, data) {
  return request({
    url: `/users/${id}/valid`,
    method: 'post',
    data
  })
}
