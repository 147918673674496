<template>
  <div>
    <a-modal
      width="400px"
      v-model="isShow"
      centered
      :mask-closable="false"
      :closable="false"
      title="提示"
      @ok="handleSubmit"
    >
      <template slot="footer">
        <a-button
          class="cancel-btn"
          @click="closeModal"
        >
          取消
        </a-button>

        <a-button
          class="submit-btn"
          @click="handleSubmit"
        >
          确定
        </a-button>
      </template>

      <div class="content">
        <span class="tips">为了避免操作与信息安全，请输入用户密码。</span>

        <a-form
          class="custom-compact-form"
          :form="form"
          :wrapper-col="{ span: 24 }"
          @submit="handleSubmit"
        >
          <a-form-item label="">
            <a-input-password
              placeholder="请输入用户密码"
              v-decorator="['password', {
                normalize: this.$lodash.trim,
                rules: [
                  { required: true, message: '请输入用户密码' },
                  { max: 100, message: '最多100个字符' },
                ]
              }]"
            />
          </a-form-item>
        </a-form>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { validUser } from '@/api/home'
export default {
  name: 'ValidPassword',
  props: {
    visible: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      form: this.$form.createForm(this, { name: 'valid_password' }),
      submitting: false
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault()
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }
      this.form.validateFields((err, values) => {
        if (!err) {
          this.submitting = true
          validUser(this.$store.getters.userId, values).then((res) => {
            if (res.code === 0) {
              // 关闭模态框
              this.isShow = false
              // 告知父组件已完成
              this.$emit('completed')
            }
            this.submitting = false
          })
        }
      })
    },

    closeModal() {
      this.isShow = false
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .ant-modal-content {
  background-color: #2a4c39;
}

/deep/ .ant-modal-header {
  border-bottom: 1px solid #848484;
  text-align: center;
  background-color: #2a4c39 !important;

  .ant-modal-title {
    color: @white !important;
  }
}

/deep/ .ant-modal-footer {
  border-top: none;
  text-align: center;
  background-color: #2a4c39 !important;
}

.content {
  display: flex;
  align-items: center;
  flex-direction: column;

  .tips {
    margin-bottom: 20px;
    color: #ffd794ff;
  }
}

.cancel-btn {
  width: 150px;
  border: none;
}

.submit-btn {
  width: 150px;
  border: none;
  background-color: #ffd794ff;
}

/deep/ .submit-btn.ant-btn:hover {
  color: rgba(0, 0, 0, 65%);
}

/deep/ .submit-btn.ant-btn:focus {
  color: rgba(0, 0, 0, 65%);
}

/deep/ .ant-divider-horizontal {
  background-color: #848484;
}
</style>
